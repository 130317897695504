<template>
    <div>
        <div class="mt-1 pa-2 " style="max-height: 400px"> 
            <h3 class=" elevation-24 primary darken-2 pa-2 white--text font-weight-bold text-xs-left">({{ticketChats.length}}) Customer Care Chats</h3>
            <v-divider></v-divider>
            <template class="" v-for="item in ticketChats">
                <v-hover v-if="!item.read" :key="item.id">
                    <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 4 : 12}`" :to="'/market_place/contact_us/detail/'+item.id" color="white" style="border-left: 5px solid red!important; cursor: pointer">
                        <v-card-text style="font-size: 12px">
                            <h3 class="teal--text"><span class="dark--text font-weight-bold teal--text">Subject: {{item.subject}}</span></h3> 
                            <span><b>Message:</b>  {{ item.message | truncate(150, '...') }}</span>
                        </v-card-text>
                    <v-divider ></v-divider>
                    </v-card>
                </v-hover>
                <v-hover v-else :key="item.id">
                    <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 4 : 12}`" color="white" :to="'/market_place/contact_us/detail/'+item.id" style="border-left: 5px solid green!important; cursor: pointer">
                        <v-card-text style="font-size: 12px">
                            <h3 class="teal--text"><span class="dark--text font-weight-bold teal--text">Subject: {{item.subject}}</span></h3> 
                            <span><b>Message:</b>  {{ item.message | truncate(150, '...') }}</span>
                        </v-card-text>
                    <v-divider></v-divider>
                    </v-card>
                </v-hover>
            </template>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                ticketChats: [],
            }
        },

        mounted(){
            // executes these after the page has been mounted
            document.title = "PettyCash Market Place | Contact Us"
            this.getTickets()
            this.$store.commit('setCloseURL', "/market_place")
        },
        methods: {
            
            async getTickets(){
                this.$store.commit('setIsLoading', true)
                
                await axios
                    .get('/api/v1/home/Market Place/contact_us/')
                    .then(response => {
                        this.ticketChats = response.data  // get the data and fill into operators
                        console.log(this.ticketChats)
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },

        },
    }
</script>

<style scoped>
    blink {
    -webkit-animation: 4s linear infinite condemned_blink_effect; /* for Safari 4.0 - 8.0 */
    animation: 4s linear infinite condemned_blink_effect;
    }

    /* for Safari 4.0 - 8.0 */
    @-webkit-keyframes condemned_blink_effect {
    0% {
        visibility: hidden;
    }
    50% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
    }

    @keyframes condemned_blink_effect {
    0% {
        visibility: hidden;
    }
    50% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
    }

</style>
